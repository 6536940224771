import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "ปัญหาระหว่างทำธุรกรรม"
    }}>{`ปัญหาระหว่างทำธุรกรรม`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "ขั้นตอนในการซื้อตัวละคร-ต้องรอนานเกินไป"
    }}>{`ขั้นตอนในการซื้อตัวละคร ต้องรอนานเกินไป`}</h2>
    <p>{`ทางเราต้องขออภัยไว้ล่วงหน้าสำหรับปัญหาซื้อตัวละครที่เกิดขึ้น เนื่องจากคำขอในการทำธุรกรรมในบางเวลาบน Polygon Chain นั้น มีความแออัดมาก จึงอาจทำให้ใช้เวลาในการสร้างตัวละครนั้นนานกว่าปกติ ( อาจใช้เวลานานถึง 30 นาที ไปจนถึง 1 ชั่วโมง )`}</p>
    <p>{`ในระหว่างการทำธุรกรรม กรุณาอย่าปิด browser tab ของเกม เนื่องจากอาจทำให้ธุรกรรมไม่สำเร็จ และไม่ได้รับตัวละคร`}</p>
    <p><img alt="transaction" src={require("./public/images/tab-1.png")} /></p>
    <p>{`ในกรณีที่ซื้อตัวละครไปแล้ว แต่ไม่ได้รับตัวละคร หรืออาจรอนานเกินไป โปรดแจ้งเราที่ `}<a parentName="p" {...{
        "href": "https://share.hsforms.com/16xae8YKwRJ6qA_0BPzc6nQ3tki0"
      }}>{`Report Form`}</a></p>
    <p>{`สำหรับกรณีที่เราตรวจสอบแล้วพบว่าธุรกรรมล้มเหลว ทางเราจะส่ง CLC ที่ท่านใช้ซื้อตัวละคร คืนไปที่กระเป๋าของท่าน`}</p>
    <p><img alt="transaction" src={require("./public/images/tab-2.png")} /></p>
    <p>{`โปรดอย่ากังวล ทางเราจะตอบและแก้ไขทุกปัญหาของท่านให้ได้โดยเร็วที่สุด และขอภัยอีกครั้งที่ทำให้ท่านเสียเวลา`}</p>
    <hr></hr>
    <h2 {...{
      "id": "rpc-network"
    }}>{`RPC Network`}</h2>
    <p>{`เราแนะนำให้ท่านใช้ RPC URL ตัวใหม่ `}<a parentName="p" {...{
        "href": "https://polygon-rpc.com"
      }}>{`https://polygon-rpc.com`}</a>{` เพื่อหลีกเลี่ยงความแออัดในการทำธุรกรรมบน Polygon Mainnet`}</p>
    <h3 {...{
      "id": "เปลี่ยน-rpc-url-อย่างไร-"
    }}>{`เปลี่ยน RPC URL อย่างไร ?`}</h3>
    <ol>
      <li parentName="ol">{`คลิกที่ไอคอน Account ของคุณใน Metamask แล้วเข้าเมนู "การตั้งค่า"`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-1.png")} /></p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`เลือกหัวข้อ "เครือข่าย" แล้วเลือกชื่อเครือข่าย "Polygon Mainnet" หรือชื่อเครือข่ายที่คุณตั้ง`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-2.png")} /></p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`เปลี่ยน RPC URL ในช่องให้เป็น `}<a parentName="li" {...{
          "href": "https://polygon-rpc.com"
        }}>{`https://polygon-rpc.com`}</a>{` แล้วทำการกดบันทึก เป็นอันเสร็จสิ้น`}</li>
    </ol>
    <p><img alt="RPC" src={require("./public/images/rpc-3.png")} /></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      